import React from "react";
import styled from "styled-components";
import CourseCard from "../common/course/CourseCard";
import { Grid as _Grid } from "@material-ui/core";

import { Wrapper, Container, Title, Subtitle } from "../global";
import { Course } from "@studytools/core/generated/graphql";
import { CourseTag } from "@studytools/core/constant/CourseTag";

type Props = {
  courseList: Course[];
};

const reducedMargin = { marginBottom: 20 };

const Resources: React.FC<Props> = ({ courseList }) => {
  const publicCourses = courseList.filter(course => course.access === "public");
  const recommendedCourses = publicCourses.filter(course => (course.tags || []).includes(CourseTag.Recommended));
  const otherCourses = publicCourses.filter(course => !(course.tags || []).includes(CourseTag.Recommended));

  return (
    <Wrapper id="resources">
      <Container>
        <Subtitle style={reducedMargin}>Sign up to watch lessons linked to an Ako Map explaining the topic.</Subtitle>
        <Title style={reducedMargin}>RECOMMENDED</Title>
        <Grid container spacing={3} justify="center">
          {recommendedCourses.map(course => {
            return (
              <GridItem item key={course.id}>
                <CourseCard course={course} />
              </GridItem>
            );
          })}
        </Grid>
        <Title style={reducedMargin}>COURSES</Title>
        <Grid container spacing={3} justify="center">
          {otherCourses.map(course => {
            return (
              <GridItem item key={course.id}>
                <CourseCard course={course} />
              </GridItem>
            );
          })}
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Resources;

const Grid = styled(_Grid)`
  margin-bottom: 16px !important;
`;
const GridItem = styled(Grid)`
  max-width: 376px;
  width: 100%;
`;
