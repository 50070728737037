import React, { useState, useEffect } from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import ExploreHeader from "../components/explore/exploreHeader";
import Footer from "../components/sections/footer";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { CourseQuery } from "../queries/course";

const ExplorePage: React.FC = () => {
  // fetch list of courses from the backend server
  const [courseList, setCourseList] = useState([]);
  useEffect(() => {
    const options = {
      method: "POST",
      body: `{ "query": "${CourseQuery}" }`,
      headers: new Headers({ "content-type": "application/json" }),
    };

    fetch("/graphql", options)
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        return [];
      })
      .then(data => setCourseList(data.data.courses))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch(err => console.log);
  }, []);

  return (
    <Layout>
      <SEO title="Explore" description="Explore Ako Map courses" />
      <Navigation />
      <ExploreHeader courseList={courseList} />
      <Footer />
    </Layout>
  );
};

export default ExplorePage;
